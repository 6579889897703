import styled, { keyframes } from "styled-components";

const glow = keyframes`
from {
    text-shadow: 0 0 10px #ffd400, 0 0 20px #ffd400, 0 0 30px #ffd400
      0 0 40px #ffd400 0 0 50px #ffd400 0 0 60px #ffd400 0 0 70px #ffd400;
  }
  to {
    text-shadow: 0 0 20px #ffd400, 0 0 30px #ffd400, 0 0 40px #ffd400,
      0 0 50px #ffd400, 0 0 60px #ffd400, 0 0 70px #ffd400, 0 0 80px #ffd400;
  }
`;

export const BoldGlow = styled.strong`
  font-size: 15px;
  color: black;
  //   text-align: center;
  -webkit-animation: ${glow} 10s ease-in-out infinite alternate;
  -moz-animation: ${glow} 10s ease-in-out infinite alternate;
  animation: ${glow} 10s ease-in-out infinite alternate;
`;
