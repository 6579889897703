import React from "react";
import { Header } from "./styles";
import AboutIcon from "../../resources/about-icon.gif";
// import AboutIcon from "../../resources/dove.gif";

const AboutUs = () => {
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <img
        src={AboutIcon}
        style={{
          width: 32,
          height: 32,
          position: "absolute",
          left: -48,
          zIndex: 3,
          top: -5,
        }}
      />
      <h3 style={{ fontSize: "22px" }}>About Us</h3>
      {/* <p> */}
      <p style={{ fontSize: "15px" }}>
        <b>AngelDAO is a Venture Decentralized Autonomous Organization</b>{" "}
        supporting a wide variety of projects with funding, software
        development, network participation and community building. Our team is
        native in the blockchain space, with experience in token model creation,
        Web3 software development, DeFi, UI/UX development and design,
        marketing, and community organization.
      </p>
    </div>
  );
};

export default AboutUs;
