import React from "react";
import PortfolioIcon from "../../resources/portfolio-icon.png";
// import PortfolioIcon from "../../resources/money.gif";
const prt = [
  { n: "Liquity", c: "#1542cd", l: "https://www.liquity.org" },
  {
    n: "Superfluid",
    c: "rgb(16, 187, 52)",
    l: "https://www.superfluid.finance",
  },
  { n: "SubQuery", c: "#fa4783", l: "https://subquery.network" },
  { n: "Impossible", c: "#00daff", l: "https://impossible.finance" },
  { n: "Neon Labs", c: "rgb(195, 0, 225)", l: "https://neon-labs.org" },
  { n: "Atlendis", c: "rgb(246, 105, 189)", l: "https://atlendis.io" },
  { n: "LI.FI", c: "#000000", l: "https://li.fi" },
  { n: "Astaria", c: "#e2a78c", l: "https://www.astaria.xyz" },
];

const prt2 = [
  { n: "Fuji", c: "rgb(219 2 73)", l: "https://app.fujidao.org" },
  { n: "Babylon", c: "#937dff", l: "https://www.babylon.finance" },
  { n: "deBridge", c: "#6410d6", l: "https://debridge.finance" },
  { n: "MonoX", c: "#3dcf97", l: "https://monox.finance" },
  { n: "Aurora", c: "rgb(120, 214, 75)", l: "https://aurora.dev/" },
  { n: "Morpho Labs", c: "#000070", l: "https://www.morpho.best/" },
  { n: "Mangata", c: "#0a779e", l: "https://mangata.finance" },
  { n: "Increment", c: "#0c101a", l: "https://increment.finance" },
];

const prt3 = [
  { n: "YOLOrekt", c: "#2a6dff", l: "https://www.yolorekt.finance" },
  { n: "Clover", c: "rgb(33 210 151)", l: "https://clover.finance" },
  { n: "handle", c: "#2a313e", l: "https://handle.fi/" },
  { n: "Star Atlas", c: "#fa3a00", l: "https://staratlas.com" },
  { n: "GFX Labs", c: "#c7c7c7", l: "https://gfxlabs.io/" },
  { n: "B.Protocol", c: "#23A455", l: "https://www.bprotocol.org/" },
  { n: "Hedge Labs", c: "rgb(155 109 185)", l: "https://www.hedge.so" },
  { n: "Metahood", c: "#fd5d84", l: "https://metahood.xyz" },
];

const mobile = window.innerWidth < 500;

const Portfolio = () => {
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <img
        alt="stuff"
        src={PortfolioIcon}
        style={{
          width: 32,
          height: 32,
          position: "absolute",
          left: -48,
          top: -5,
        }}
      />
      <h3 style={{ fontSize: "22px" }}>Portfolio</h3>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {mobile ? (
          <div>
            {[...prt, ...prt2, ...prt3].map((project) => {
              return (
                <div style={{ fontSize: "15px", marginTop: "10px" }}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={project.l}
                    style={{ textDecoration: "underline", color: project.c }}
                  >
                    {project.n}
                  </a>
                </div>
              );
            })}
          </div>
        ) : (
          <>
            <div>
              {prt.map((project) => {
                return (
                  <div style={{ fontSize: "15px", marginTop: "10px" }}>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={project.l}
                      style={{ textDecoration: "underline", color: project.c }}
                    >
                      {project.n}
                    </a>
                  </div>
                );
              })}
            </div>
            <div>
              {prt2.map((project) => {
                return (
                  <div style={{ fontSize: "15px", marginTop: "10px" }}>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={project.l}
                      style={{ textDecoration: "underline", color: project.c }}
                    >
                      {project.n}
                    </a>
                  </div>
                );
              })}
            </div>
            <div>
              {prt3.map((project) => {
                return (
                  <div style={{ fontSize: "15px", marginTop: "10px" }}>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={project.l}
                      style={{ textDecoration: "underline", color: project.c }}
                    >
                      {project.n}
                    </a>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Portfolio;
