// import logo from './logo.svg';
// import './App.css';
// import Logo from "./resources/AngelDAO.png";
import React, { useState } from "react";
import Home from "./containers/Home";
import Player from "./components/Player";
import { Howl, Howler } from "howler";
//import Track from "./resources/ave_maria.mp3";
import clouds from "./resources/clouds.png";
import styled, { keyframes } from 'styled-components'
function App() {
  //const [sound, setSound] = useState(new Howl({ src: Track, html5: true }));
  return (
    <BackgroundContainer id="background">
      <Home /> {/*sound={sound}*/}
      {/*
      We need to how the audio mp3 somewhere else so we don't pay for bandiwdth for this. And all GIF's should be hosted on imgur or somewhere else and displayed as videos to load async
      
      <Player sound={sound} />
      */}
    </BackgroundContainer>
  );
}

const CloudAnim = keyframes`
    0% { background-position: 0 0; }
    100% { background-position: -900px 0; }
`

const BackgroundContainer = styled.div`
  background-image: url(${clouds});
  background-size: 900px;
  background-position:0 0;
  width: 100%;
  position: relative;
  min-height: 100vh;
  max-height:100vh;
  overflow: hidden;
  z-index: 10;
  overscroll-behavior-block: none;
    -webkit-animation: ${CloudAnim} 70s linear infinite;
    animation: ${CloudAnim} 70s linear infinite;
`;

/*

  @media (max-width: 500px) {
    min-height: -webkit-fill-available;
  }*/

export default App;
