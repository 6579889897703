import { blue } from "@material-ui/core/colors";
import React from "react";
// import Email from "../../resources/email.png";
import ContactIcon from "../../resources/bow_and_arrow.png";
// import ContactIcon from "../../resources/contact.gif";
const Participate = () => {
  return (
    <div style={{ width: "100%", paddingBottom: "5px", position: "relative" }}>
      <img
        src={ContactIcon}
        style={{ width: 32, height: 32, position: "absolute", left: -43 }}
      />
      <h3 style={{ fontSize: "22px" }}>Participate</h3>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://work.angeldao.org/0035b703090641faa2a9425d032d2311?v=ef6a3257f4a74059a45183ff575ffeda"
        style={{
          fontSize: "15px",
          display: "block",
          margin: "10px 0px",
          color: "blue",
        }}
      >
        @jobs
      </a>
      <a
        href="https://work.angeldao.org/416ca268531a4058a10d92cca29c029f?v=086e929965e6408587816c49c66262ef"
        target="_blank"
        rel="noreferrer"
        style={{
          fontSize: "15px",
          display: "block",
          margin: "10px 0px",
          color: "blue",
        }}
      >
        @bounties
      </a>
      {/* <img style={{ width: "150px", height: "auto" }} src={Email} /> */}
    </div>
  );
};

export default Participate;
