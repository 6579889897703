import { blue } from "@material-ui/core/colors";
import React from "react";
// import Email from "../../resources/email.png";
import ContactIcon from "../../resources/contact-icon.png";
// import ContactIcon from "../../resources/contact.gif";
const ContactUs = () => {
  return (
    <div style={{ width: "100%", paddingBottom: "5px", position: "relative" }}>
      <img
        src={ContactIcon}
        style={{ width: 32, height: 32, position: "absolute", left: -43 }}
      />
      <h3 style={{ fontSize: "22px" }}>Contact Us</h3>
      <a
        target="_blank"
        rel="noreferrer"
        href="mailto:team@angeldao.org"
        style={{
          fontSize: "15px",
          display: "block",
          margin: "10px 0px",
          color: "blue",
        }}
      >
        team@angeldao.org
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://twitter.com/AngelDAOorg"
        style={{
          fontSize: "15px",
          display: "block",
          margin: "10px 0px",
          color: "blue",
        }}
      >
        @twitter
      </a>
      <a
        href="https://github.com/AngelDao"
        target="_blank"
        rel="noreferrer"
        style={{
          fontSize: "15px",
          display: "block",
          margin: "10px 0px",
          color: "blue",
        }}
      >
        @github
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.linkedin.com/company/angel-dao"
        style={{
          fontSize: "15px",
          display: "block",
          margin: "10px 0px",
          color: "blue",
        }}
      >
        @linkedin
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://medium.com/@AngelDAO"
        style={{
          fontSize: "15px",
          display: "block",
          margin: "10px 0px",
          color: "blue",
        }}
      >
        @medium
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.youtube.com/channel/UC82iUQ1lAPvQrOUKdF_xKcQ"
        style={{
          fontSize: "15px",
          display: "block",
          margin: "10px 0px",
          color: "blue",
        }}
      >
        @youtube
      </a>
      {/* <img style={{ width: "150px", height: "auto" }} src={Email} /> */}
    </div>
  );
};

export default ContactUs;
