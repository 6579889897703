import React from "react";
import { BoldGlow } from "./styles";
import ThesisIcon from "../../resources/thesis-icon.png";
// import ThesisIcon from "../../resources/scroll.gif";

const Thesis = () => {
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <img
        src={ThesisIcon}
        style={{
          width: 32,
          height: 32,
          position: "absolute",
          left: -48,
          top: -5,
        }}
      />
      <h3 style={{ fontSize: "22px" }}>Thesis</h3>
      <p style={{ fontSize: "15px" }}>
        <b>
          We believe that the inevitability of Web 3 ensures the value of
          distributed systems.{" "}
        </b>
        With that, we are focused on offering venture, technical, and marketing
        support to projects driving global adoption and technological innovation
        for distributed systems and decentralized finance. We have a data driven
        research process that we use to identify opportunities, while still
        maintaining a collaborative effort between DAO members to form consensus
        on decisions.
      </p>
    </div>
  );
};

export default Thesis;
