import styled from "styled-components";
import Scroll from "../../resources/scroll.png";

export const TextContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  // overscroll-behavior-block: none;
  // -webkit-overflow-scrolling: touch
  display: flex;
  justify-content: center;
  padding-left: 0px 20px;
  // margin-left: -40px;
  max-height: calc(60vh - 80px);
  @media (max-width: 900px) {
    max-height: calc(65vh - 80px);
    margin-bottom: 20px;
  }
  @media (max-height: 600px) {
    max-height: calc(60vh - 100px); !important;
    margin-bottom: 20px;
  }
`;
export const ScrollBackground = styled.div`
  overscroll-behavior-block: none;
  max-height: calc(100% - 80px);
  background-image: url(${Scroll});
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 40px 40px 40px 0px;

  @media (max-width: 900px) {
    // padding: 40px;
    padding: 30px 20px 30px 0px;
  }
`;

export const ScrollContainer = styled.div`
  max-height: 60vh;
  // min-height: -webkit-fill-available;
  overscroll-behavior-block: none;
  @media (max-width: 900px) {
    max-height: 65vh;
    font-size: 11px !important;
  }
  @media (max-height: 600px) {
    max-height: 60vh !important;
    font-size: 11px !important;
  }

`;
