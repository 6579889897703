import { blue } from "@material-ui/core/colors";
import React from "react";
import Meir from "../../resources/meir.png";
import John from "../../resources/john.png";
import Daniel from "../../resources/daniel.png";
// import Email from "../../resources/email.png";
import TeamIcon from "../../resources/pick.png";
// import ContactIcon from "../../resources/contact.gif";
import styled from "styled-components";

const TeamMemberContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 500px) {
    display: block;
    justify-content: none;
    align-items: start;
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (max-width: 500px) {
    margin-bottom: 20px;
    width: 75%;
    // align-items: start;
    // align-text: center;
    // margin-left: 25px;
  }
`;
const Team = () => {
  return (
    <div style={{ width: "100%", paddingBottom: "5px", position: "relative" }}>
      <img
        src={TeamIcon}
        style={{ width: 32, height: 32, position: "absolute", left: -43 }}
      />
      <h3 style={{ fontSize: "22px" }}>Team</h3>
      <TeamMemberContainer>
        <ProfileContainer>
          <img src={Meir} style={{ width: "80px", height: "80px" }} />
          <h3
            style={{ marginTop: "8px", fontSize: "15px", marginBottom: "5px" }}
          >
            Meir Bank
          </h3>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/meirbank/"
            style={{ color: "blue", fontSize: "14px", marginTop: "4px" }}
          >
            @linkedin
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/MeirBank"
            style={{ color: "blue", fontSize: "14px", marginTop: "4px" }}
          >
            @twitter
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/meirbank"
            style={{ color: "blue", fontSize: "14px", marginTop: "4px" }}
          >
            @github
          </a>
        </ProfileContainer>
        <ProfileContainer>
          <img src={John} style={{ width: "80px", height: "80px" }} />
          <h3
            style={{ marginTop: "8px", fontSize: "15px", marginBottom: "5px" }}
          >
            John Gower
          </h3>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/blockchase/"
            style={{ color: "blue", fontSize: "14px", marginTop: "4px" }}
          >
            @linkedin
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/Blockchase"
            style={{ color: "blue", fontSize: "14px", marginTop: "4px" }}
          >
            @twitter
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://blockchase.com/"
            style={{ color: "blue", fontSize: "14px", marginTop: "4px" }}
          >
            @website
          </a>
        </ProfileContainer>
        <ProfileContainer>
          <img src={Daniel} style={{ width: "80px", height: "80px" }} />
          <h3
            style={{ marginTop: "8px", fontSize: "15px", marginBottom: "5px" }}
          >
            Daniel Warrick
          </h3>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/daniel-warrick-08b22b169/"
            style={{ color: "blue", fontSize: "14px", marginTop: "4px" }}
          >
            @linkedin
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/Danuuuule"
            style={{ color: "blue", fontSize: "14px", marginTop: "4px" }}
          >
            @twitter
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/DanWizard"
            style={{ color: "blue", fontSize: "14px", marginTop: "4px" }}
          >
            @github
          </a>
        </ProfileContainer>
      </TeamMemberContainer>
      {/* <img style={{ width: "150px", height: "auto" }} src={Email} /> */}
    </div>
  );
};

export default Team;
