import React from "react";
import MissionStatement from "../../components/MissionStatement";
// import Logo from "../../resources/AngelDAO-Logo-final-yellow.gif";
// import Logo from "../../resources/AngelDAO-Logo.gif";
// import Logo from "../../resources/Logo.gif";
import Logo from "../../resources/AngelDAO.gif";
import AboutUs from "../../components/AboutUs";
import Capabilities from "../../components/Capabilities";
import Technology from "../../components/Technology";
import Portfolio from "../../components/Portfolio";
import ContactUs from "../../components/ContactUs";
import Participate from "../../components/Participate";
import Thesis from "../../components/Thesis";
import Team from "../../components/Team";
import { TextContainer, ScrollBackground, ScrollContainer } from "./styles";

const containerStyles = {
  width: "100%",
  minHeight: "100vh",
  maxHeight: "100vh",
  display: "flex",
  justifyContent: "center",

  // position: "relative",
  // background: "lightblue",
};

const contentContainerStyles = {
  // background: "lightblue",
  // border: "1px solid black",
  display: "flex",
  alignItems: "center",
  minHeight: "100vh",
  maxHeight: "100vh",
  flexDirection: "column",
  position: "relative",
};

const imageStyles = {
  width: "auto",
  marginBottom: 20,
};

class Home extends React.Component {
  /*state = { width: 0, height: 0 };
  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }*/
  render() {
    /*const mobile = window.innerWidth < 900;
    const height = window.innerHeight;
    const scrollHeight = height / 2;*/

    return (
      <>
        <div style={containerStyles}>
          <div
            id="logo-container"
            style={{
              ...contentContainerStyles,
            }}
          >
            <img
              id="logo-image"
              style={{
                ...imageStyles,
              }}
              src={Logo}
            />
            <ScrollContainer>
              <ScrollBackground>
                <TextContainer>
                  <div style={{ width: "80%", marginLeft: 65 }}>
                    <Thesis />
                    <Technology />
                    <AboutUs />
                    <Team />
                    <Portfolio />
                    <ContactUs />
                    <Participate />
                  </div>
                </TextContainer>
              </ScrollBackground>
            </ScrollContainer>
          </div>
        </div>
      </>
    );
  }
}

export default Home;
