import React from "react";
import TechnologyIcon from "../../resources/technology-icon.png";
// import TechnologyIcon from "../../resources/technology.gif";
const Technology = () => {
  return (
    <div style={{ width: "90%", position: "relative" }}>
      <img
        src={TechnologyIcon}
        style={{
          width: 38,
          height: 38,
          position: "absolute",
          left: -50,
          top: -5,
        }}
      />
      <h3 style={{ fontSize: "22px" }}>Technology</h3>
      <p style={{ fontSize: "15px" }}>
        <b>
          Our DAO is deployed on{" "}
          <a
            style={{ color: "blue" }}
            target="_blank"
            rel="noreferrer"
            href="https://aragon.org"
          >
            Aragon
          </a>{" "}
          and on Gnosis as a{" "}
          <a
            style={{ color: "blue" }}
            target="_blank"
            rel="noreferrer"
            href="https://gnosis-safe.io"
          >
            SAFE
          </a>
        </b>
        . We whitelist member Ethereum addresses that can vote on DAO decisions
        which represents their ownership. The quorum is simply <b>majority</b>{" "}
        based on what current member count is. Our DAO technology is fully
        upgradeable, designed to adapt to create new features for our members.
      </p>
    </div>
  );
};

export default Technology;
